import React from 'react';
import "core-js/stable";
import "regenerator-runtime/runtime";
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './reducers';
import { Provider } from 'react-redux';
const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    {/* <Main> */}
      <App />
    {/* </Main> */}
  </Provider>
);
